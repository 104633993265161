import Axios from 'axios';

export default {

  data() {
    return {
      specialities: [],
      banks: [],
      company: {
        approved_hirings: [],
        reproved_hirings: [],
        waiting_hirings: [],
        address: false,
        saved: false,
      },
      users: [],
      user: {},
      userType: 'professionals',
      TOKEN: '',
      formFile: {
        id: '',
        name: '',
        file: '',
        user_id: '',
        message: '',
        fileName: 'Clique aqui e selecione o arquivo',
      },
      formError:{
        error: '',
        file: '',
        user_id: '',
        message: '',
        size: ''
      },
      show: '',
      see: 10,
      all_files: {},
      files: {},
      url: ''
    }
  },

  mounted() {
    this.TOKEN = localStorage.getItem('token');
    this.filesCompany();

    axios
    .get('/api/v1/specialities', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.specialities = response.data.data;
      } else {
        console.log(response.data.error);
      }
    });

    axios
    .get("/api/v1/banks", {
      headers: {
        Authorization: "Bearer " + this.TOKEN,
      },
    })
    .then((response) => {
      if (!response.data.error) {
        this.banks = response.data.data;
      }
    });

    axios
      .get('/api/v1/companies/' + this.$route.params.id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        response.data.data.saved = false;
        response.data.data.approved_hirings = [];
        response.data.data.reproved_hirings = [];
        response.data.data.waiting_hirings = [];

        for (var i = 0; i < response.data.data.hirings.length; i++) {

          response.data.data.hirings[i].preselected = [];
          response.data.data.hirings[i].sended = [];
          response.data.data.hirings[i].open = false;

          if (response.data.data.hirings[i].status >= 5) {
            response.data.data.reproved_hirings.push(response.data.data.hirings[i]);
          } else if (response.data.data.hirings[i].status == 4) {
            response.data.data.approved_hirings.push(response.data.data.hirings[i]);
          } else {
            response.data.data.waiting_hirings.push(response.data.data.hirings[i]);
          }
        }

        this.company = response.data.data;

    });
  },

  methods: {
    sumApprovedValues: function (hirings) {
      var sum = 0;
      hirings.forEach((hiring, index) => {
        sum += this.costByPlan(hiring.approved_value, hiring.plan);
      });
      return sum;
    },

    toCurrency(val) {
      return (val/100).toLocaleString("pt-BR", {style: "currency", currency: "BRL", minimumFractionDigits: 2});
    },

    costByPlan(value, plan) {
      switch (plan) {
        case 1:
          value = value / 0.85;
          break;
        case 2:
          value = value / 0.85;
          break;
        case 3:
          value = value / 0.75;
          break;
        case 4:
          value = value / 0.8928571429;
          break;
        case 5:
            value = value;
            break;
        case 6:
          value = value / 0.70;
          break;
        case 7:
          value = value / 0.80;
          break;
        default:
        value = value / 0.85;
      }
      return value;
    },

    toggleOpen(hiring) {
      hiring.open = !hiring.open;
      if (hiring.open) hiring.preselected = [];
      if (hiring.open) this.cardOpen(hiring);
    },

    cardOpen(hiring) {
      axios
        .get('/api/v1/users/select-for-hiring/' + hiring.id, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            hiring.preselected = response.data.data;
          } else{
            console.log(response.data.error);
          }
      });
    },

    approveBriefing: function (param) {
      console.log(param);
      var data = {
        selected: param.selected,
        reveal: param.reveal,
      };
      axios
        .put('/api/v1/hirings/'+param.hiring+'/approve-briefing', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            param.hiring = response.data.data;
          } else{
            console.log(response.data.error);
          }
      });
    },

    changeCommunity() {
      var data = {
        action: this.company.community_flag,
      };
      axios
        .put('/api/v1/companies/'+this.company.id+'/community-access', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
      });
    },

    changePlan() {
      let _this = this;
      var data = {
        plan: this.company.plan,
      };
      axios
        .put('/api/v1/companies/'+this.company.id+'/plan', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          _this.company.saved = true;
          setTimeout(function(){ _this.company.saved = false; }, 2000);
      });
    },

    handleFileUpload(event){
      this.formError.error = false
      if(event.target.files.length == 1){
        var fileSize = event.target.files[0].size / 1000
        if(fileSize > 10000){
          this.formError.error = true
          this.formError.size = 'Arquivo não pode exceder 10MB'
        }
        this.formFile.fileName = event.target.files[0].name
        this.url = event.target.files[0].name
        this.formFile.file = event.target.files[0]
      } else{ 
        this.formFile.fileName = 'Clique aqui e selecione o arquivo'
      }
    },
    
    uploadFile() {
      var form = ''
      form = new FormData()
      form.append('file', this.formFile.file)
      form.set('name', this.formFile.name)
      form.set('user_id', this.formFile.user_id)
      form.set('message', this.formFile.message)
      form.set('company_id', this.$route.params.id)

      axios
        .post('/api/v1/files', form, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then(response => {
          if (!response.data.error) {
            this.getUser(this.formFile.user_id)
            this.show = "show"
            this.modal = 'close'                 
          } else {
            this.error(response.data.error)
          }
      });
    },

    getUser(id){
      axios
        .get('/api/v1/users/' + id, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            this.user = response.data.data;
          } else {
            console.log(response.data.error);
          }
        });
    },

    closeModal(){
      location.replace(location.pathname) 
    },

    editFile(id, url){
      this.url = url.substr(6)
      axios .get('/api/v1/files/' + id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      }).then(response => {
          this.formFile = response.data.data
          this.formFile.user_id = response.data.data.users[0].id
      });
    },

    filesCompany(){
      axios .get('/api/v1/files?company_id='+ this.$route.params.id, {
       headers: {
         Authorization: 'Bearer ' + this.TOKEN,
       }
     }).then(response => {
         this.all_files = response.data.data
         this.files = response.data.data.slice(0,10)
     });
    },

    seeMore(x){
      this.see = this.see + x
      this.files = this.all_files.slice(0,this.see)

      if(this.all_files.length == this.files.length){
        this.all_files = 10
      }
    },
    
    updateUploadFile(){
      var form = ''
      form = new FormData()
      form.append('file', this.formFile.file)
      form.append('_method', 'PUT');
      form.set('name', this.formFile.name)
      form.set('user_id', this.formFile.user_id)
      form.set('message', this.formFile.message)
      form.set('company_id', this.$route.params.id)

      axios
        .post('/api/v1/files/' + this.formFile.id, form, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then(response => {
          if (!response.data.error) {
            this.getUser(this.formFile.user_id)
            this.show = "show"
            this.modal = 'close'                 
          } else {
            this.error(response.data.error)
          }
      });
    },

    clearModal(){
      this.formFile.id = ''
      this.formFile.name = ''
      this.formFile.file = ''
      this.formFile.user_id = ''
      this.formFile.message = ''
      this.formFile.fileName  = 'Clique aqui e selecione o arquivo'
      this.url = ''
    },

    error(err){
      if(err.file){
        this.formError.file =  err.file[0]
      } else{this.formError.file = ''}

      if(err.user_id){
        this.formError.user_id = err.user_id[0]
      }else {this.formError.user_id = ''}

      if(err.message){
        this.formError.message = err.message[0]
      } else {this.formError.message = ''}

      if(err){
        this.formError.error = true
      } else {this.formError.error = false}
    }
  },

}
